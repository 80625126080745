import manageMaintenance from './actions/manageMaintenance';

export type Setters = {
    [key: string]: (arg: any) => void;
};

type ReceivedMessageType = {
    [key: string]: (message: any, lastJsonMessage: any, setters: Setters, environment: string) => void;
};

const messageHandler: ReceivedMessageType = {
    RETRIEVE: manageMaintenance,
};

export default messageHandler;
