import { MessageData } from '../../useWebSocket';
import { Setters } from '../messageHandler';

type FilteredData = {
    feature_name: string;
    isActive: boolean;
};

const manageMaintenance = (message: MessageData, lastJsonMessage: any, setters: Setters, environment: string) => {
    const { setInMaintenance } = setters;

    const filterSiteMaintenance: FilteredData = message.data.filter(
        (d: any) =>
            d.application_id === 'FOODI-BACKOFFICE' &&
            d.environment === environment &&
            d.feature_name === 'SITE_MAINTENANCE'
    )?.[0];

    if (filterSiteMaintenance !== undefined) {
        setInMaintenance(filterSiteMaintenance.isActive);
    }
};

export default manageMaintenance;
