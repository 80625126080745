// @ts-nocheck
import React, { Suspense } from 'react';
import { ApolloProvider } from '@apollo/client';
import styled, { ThemeProvider } from 'styled-components';
import { toast } from 'react-toastify';
import 'services/i18n';
import { client } from 'services/apollo/client';
import { appTheme, GlobalStyle } from 'theme';
import Router from 'Router';
import { GlobalLoader } from 'components/Loader';
import { CloseButton } from 'components/Toast';
import 'react-toastify/dist/ReactToastify.css';
import { BaseModalBackground, ModalProvider } from 'styled-react-modal';
import Maintenance from 'pages/Maintenance';
import { ToggleWebsocketProvider, useToggleWebsocket } from 'components/Websocket/useWebSocket';

toast.configure({
    hideProgressBar: true,
    toastClassName: 'app-toast',
    className: 'app-toast-container',
    closeButton: <CloseButton />,
});

const FadingBackground = styled(BaseModalBackground)`
    opacity: ${(props) => props.opacity};
    transition: all 0.3s ease-in-out;
`;

const App: React.FC = () => {
    const {
        maintenance: { inMaintenance, isTester },
    } = useToggleWebsocket();

    return (
        <ThemeProvider theme={appTheme}>
            <ModalProvider backgroundComponent={FadingBackground}>
                <ApolloProvider client={client}>
                    <ToggleWebsocketProvider>
                        <GlobalStyle />
                        {inMaintenance && !isTester ? (
                            <Maintenance />
                        ) : (
                            <Suspense fallback={<GlobalLoader />}>
                                <Router />
                            </Suspense>
                        )}
                    </ToggleWebsocketProvider>
                </ApolloProvider>
            </ModalProvider>
        </ThemeProvider>
    );
};

export default App;
