import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { HelpTooltip } from 'components'

interface IInputLabelProps {
    name: ReactElement | ReactElement[] | string | null;
    comment?: ReactElement | ReactElement[] | string;
    htmlFor?: string;
    showRequiredAsterisk?: boolean;
    inline?: boolean;
    fontWeight?: string;
    fontSize?: string;
    noMarginRight?: boolean;
    marginBottom?: string;
    infoLabel?: ReactElement | ReactElement[] | string;
    labelContainerStyle?: any;
    width?: number;
    testID?: string;
    withTooltip?: boolean;
    tooltipContent?: string | null;
    tooltipContainerStyle?: any;
}

export function InputLabel({
    name,
    htmlFor,
    showRequiredAsterisk,
    inline,
    comment,
    fontWeight = 'normal',
    noMarginRight,
    fontSize = 'M',
    marginBottom,
    infoLabel = '',
    labelContainerStyle,
    width,
    testID,
    withTooltip = false,
    tooltipContent = '',
    tooltipContainerStyle = {},
}: IInputLabelProps) {
    return (
        <StyledInputLabel
            inline={inline}
            htmlFor={htmlFor}
            fontWeight={fontWeight}
            noMarginRight={noMarginRight}
            fontSize={fontSize}
            marginBottom={marginBottom}
            width={width}
            style={labelContainerStyle}
            data-test={testID}
        >
            {name}
            {comment && <Comment> ({comment})</Comment>}
            {showRequiredAsterisk && <Required> *</Required>}
            {infoLabel}
            {withTooltip && tooltipContent && (
                <TooltipWrapper>
                  { // @ts-ignore 
                    <HelpTooltip
                      tooltipContent={tooltipContent}
                      iconSize={20}
                      tooltipContainerStyle={tooltipContainerStyle}
                      {...(testID && {testID:`${testID}-tooltip`})}
                    />
                  }
                </TooltipWrapper>
            )}
        </StyledInputLabel>
    );
}

interface IInfoLabelProps {
    name?: ReactElement | ReactElement[] | string | null;
    inline?: boolean;
    enable?: boolean | undefined;
    fontStyle?: string | undefined;
    fontColor?: string | undefined;
    capitalize?: boolean | undefined;
    fontWeight?: string | undefined;
    paddingRight?: number | undefined;
    paddingBottom?: number | undefined;
    fontSize?: string;
    withTooltip?: boolean;
    tooltipContent?: string | null;
    tooltipContainerStyle?: any;
    testID?: string;
}

export function InfoLabel({
    name,
    enable = true,
    inline = false,
    fontStyle = '',
    fontColor,
    capitalize = false,
    fontWeight = 'normal',
    paddingRight = undefined,
    paddingBottom = undefined,
    fontSize = 'M',
    withTooltip = false,
    tooltipContent = '',
    tooltipContainerStyle = {},
    testID
}: IInfoLabelProps) {
    return (
        <StyledLabel
            {...{ enable, inline, fontStyle, fontColor, capitalize, fontWeight, paddingRight, paddingBottom, fontSize }}
            data-test={testID}
        >
            {name}
            {withTooltip && tooltipContent && (
                <TooltipWrapper>
                  { // @ts-ignore 
                    <HelpTooltip
                      tooltipContent={tooltipContent}
                      iconSize={20}
                      tooltipContainerStyle={tooltipContainerStyle}
                      {...(testID && {testID:`${testID}-tooltip`})}
                    />
                  }
                </TooltipWrapper>
            )}
        </StyledLabel>
    );
}

interface IStyledInfoTagProps {
    inline?: boolean;
    enable?: boolean | undefined;
    capitalize: boolean;
    fontStyle?: string | undefined;
    fontColor?: string | undefined;
    fontWeight?: string | undefined;
    paddingRight?: number | undefined;
    paddingBottom?: number | undefined;
    fontSize?: string;
}

const StyledLabel = styled.span<IStyledInfoTagProps>`
    display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
    padding-right: ${({ theme, inline, paddingRight }) =>
        paddingRight ? paddingRight : inline ? theme.spacing.s : 0}px;
    padding-bottom: ${({ theme, inline, paddingBottom }) =>
        paddingBottom ? paddingBottom : inline ? 0 : theme.spacing.s}px;
    font-size: ${({ theme, fontSize }) => theme.typography[`fontSize${fontSize}`]}px;
    color: ${({ theme, fontColor }) => (fontColor !== undefined ? fontColor : theme.color.grey[6])};
    opacity: ${({ enable }) => (enable === false ? 0.33 : 1)};
    font-style: ${({ fontStyle }) => (fontStyle !== undefined ? fontStyle : '')};
    ::first-letter {
        text-transform: ${({ capitalize }) => (capitalize ? 'capitalize' : 'none')};
    }
    font-weight: ${({ fontWeight }) => fontWeight};
`;

type IStyledInputLabel = Pick<
    IInputLabelProps,
    'inline' | 'fontWeight' | 'noMarginRight' | 'fontSize' | 'marginBottom' | 'width'
>;

const StyledInputLabel = styled.label<IStyledInputLabel>`
    margin-bottom: ${({ theme, inline, marginBottom = 's' }) => (inline ? 0 : theme.spacing[marginBottom])}px;
    margin-right: ${({ theme, inline, noMarginRight }) => (inline && !noMarginRight ? theme.spacing.s : 0)}px;
    font-size: ${({ theme, fontSize }) => theme.typography[`fontSize${fontSize}`]}px;
    color: ${({ theme }) => theme.color.grey[6]};
    font-weight: ${({ fontWeight }) => fontWeight};
    width: ${({ width }) => width}px;
`;

const Comment = styled.span`
    font-size: ${({ theme }) => theme.typography.fontSizeXS}px;
    font-style: italic;
`;

const Required = styled.span`
    color: ${({ theme }) => theme.color.common.darkRed};
`;

const TooltipWrapper = styled.div`
    display: inline-block;
    position: relative;
    margin-left: ${({ theme }) => theme.spacing.xs}px;
`;
