import React, { useState, useEffect } from 'react';
import { Mutation } from '@apollo/client/react/components';
import { loader } from 'graphql.macro';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import get from 'lodash.get';
import { getArticleFamilyOptions } from 'services/articleService';
import { getNodes } from 'services/queryService';
import { Button, NumberInput, TableWithOptions, Icon } from 'components';
import { ButtonType, IconButton } from 'components/Button';
import { toast } from 'components/Toast';
import { updateOfferItemVariables } from 'types/updateOfferItem';
import { listOfferItems, listOfferItems_list_edges_node_OfferItem } from 'types/listOfferItems';
import LocalCatalogTablePanel from '../../../../components/Layout/LocalCatalogTablePanel';
import { QueryResult } from 'localTypes';
import { StyledTooltip } from 'pages/Communication/CommunicationPage/CommunicationListTable';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import EditOfferItemPanel from 'pages/TableService/DailyOffersPage/EditOfferItemPanel';
import OfferItemsToggle from 'pages/ClickCollect/DailyOffersPage/OfferDetailsPage/OfferItemsToggle';
import { OfferItemFormulaToSell, OfferItemToSell } from 'types/globalTypes';
import { FormulasTable, formulasKey } from './FormulasTable';
import OfferItemsFormulaToggle from './OfferItemsFormulaToggle';
import { CheckOfferValidModal, TArticleErrorLabels } from 'components/Modal';

enum QueryParamKeys {
    FAMILY = 'article.family',
}

const LIST_OFFER_ITEMS_QUERY = loader('../query/listOfferItems.gql');
const UPDATE_OFFER_ITEM_MUTATION = loader('../../../../query/updateOfferItem.gql');
const UPDATE_FORMULA_ITEM_MUTATION = loader('../query/updateOfferItemFormula.gql')
const ICON_WIDTH = 20;

type updatedListTableServiceOfferItem = {
    value: number | string;
    id: string;
    quantityOverall: number;
    idLocalArticle?: string;
    isStockShared: boolean;
};

export function OfferItem({
    item: { inheritedLabel, quantityRemaining, quantityOverall, id, localArticle, toSell, isStockShared, isFormulaItem },
    valuesAtZero,
    onChange,
    onSubmit,
    onClick,
    hasContainerColumn = false,
    onToSellUpdate,
    disableStockInput = false,
    boldLabel = false,
    tags = [],
    disableToogleToSell = false,
    preCheckArticle,
    onErrorArticle,
}: {
    item: listOfferItems_list_edges_node_OfferItem;
    valuesAtZero: boolean;
    onChange: (item: updatedListTableServiceOfferItem) => void;
    onSubmit: (item: updatedListTableServiceOfferItem) => void;
    hasContainerColumn?: boolean;
    onClick?: () => void;
    onToSellUpdate: (id: string, toSell: OfferItemToSell | OfferItemFormulaToSell) => void;
    disableStockInput?: boolean;
    boldLabel?: boolean;
    tags: any[];
    disableToogleToSell?: boolean;
    preCheckArticle: ((articleId:string) => any) | undefined
    onErrorArticle?: (items:any) => void
}) {
    const [numberInput, setNumberInput] = useState<number | string>(0);
    useEffect(() => {
        if (valuesAtZero) {
            setNumberInput(0);
        }
    }, [valuesAtZero]);
    return (
        <>
            <td
                style={{
                    width: hasContainerColumn ? '25%' : '30%',
                    maxWidth: '220px',
                    overflow: 'hidden',
                    overflowWrap: 'break-word',
                    fontWeight: boldLabel ? 'bold' : 'normal',
                }}
            >
                {inheritedLabel} {tags && tags.map(tag => tag)}
            </td>
            {hasContainerColumn && !localArticle?.container ? (
                <td style={{ width: '5%' }} />
            ) : (
                localArticle?.container && (
                    <td style={{ width: '5%' }}>
                        {localArticle.container !== null && (
                            <StyledTooltip toolTipContent={localArticle.container.label}>
                                <Icon.Container width={ICON_WIDTH} height={ICON_WIDTH} />
                            </StyledTooltip>
                        )}
                    </td>
                )
            )}
            <td style={{ width: '27%', paddingLeft: '4.3%' }}>
                <TdWrapper>
                    <NumberInput
                        disabled={disableStockInput}
                        value={numberInput}
                        onChange={(value: number | string) => {
                            setNumberInput(value);
                            const finalValue = typeof value === 'number' ? value : 0;
                            onChange({ id, quantityOverall, value: finalValue, isStockShared });
                        }}
                    />
                </TdWrapper>
            </td>
            <td style={{ width: '15%' }}>
                <TdWrapper>
                    <QuantityWrapper>{quantityRemaining}</QuantityWrapper>
                </TdWrapper>
            </td>
            <td style={{ width: '10%' }}>
                <TdWrapper>
                {!disableToogleToSell && (
                    <>
                     {!isFormulaItem && (
                        <OfferItemsToggle
                          id={id}
                          toSell={toSell}
                          cashSystemCode={localArticle.cashSystemCode}
                          onToSellUpdate={onToSellUpdate}
                          preCheckArticle={preCheckArticle}
                          onErrorArticle={onErrorArticle}
                        />
                     )}
                     {isFormulaItem && (
                        <OfferItemsFormulaToggle
                          id={id}
                          // @ts-ignore
                          toSell={toSell}
                          onToSellUpdate={onToSellUpdate}
                          preCheckArticle={preCheckArticle}
                          onErrorArticle={onErrorArticle}
                        />
                     )}
                    </>
                )}
                </TdWrapper>
            </td>
            <td style={{ width: '20%' }}>
                {!numberInput && onClick && (
                    <IconButton onClick={onClick}>
                        <Icon.SolidArrow />
                    </IconButton>
                )}
                <ButtonWrapper>
                    {!!numberInput && typeof numberInput === 'number' && (
                        <Button
                            inline
                            onClick={() => {
                                onSubmit({ id, quantityOverall, value: numberInput, isStockShared });
                                setNumberInput(0);
                            }}
                            display={ButtonType.VALIDATION}
                        >
                            VALIDER
                        </Button>
                    )}
                </ButtonWrapper>
            </td>
        </>
    );
}

interface IProps {
    idOffer: string;
    toggleCatalogModal: () => void;
    isCatalogPanelOpen: boolean;
    shouldRefetchItems: boolean;
    setShouldRefetchItems: (param: boolean) => void;
    formula: any;
    showValidationArticle: any;
    toggleValidationArticle: any;
    articleErrorState: {
      items: TArticleErrorLabels;
      isLoading: boolean;
    };
    preCheckArticle: (( articleId : string) => any) | undefined,
    onErrorArticle: (items:any) => void
    checkItemsList: (idOffer: string) => void;
}

const OfferItemTable = ({
    idOffer,
    toggleCatalogModal,
    isCatalogPanelOpen,
    shouldRefetchItems,
    setShouldRefetchItems,
    history,
    location,
    match: {
        params: {
          // @ts-ignore
          idHolding, idPos
        },
    },
    formula,
    showValidationArticle,
    toggleValidationArticle,
    articleErrorState,
    preCheckArticle,
    onErrorArticle,
    checkItemsList
}: IProps & RouteComponentProps) => {
    const { t } = useTranslation();

    const [updatedValues, setUpdatedValues] = useState<updatedListTableServiceOfferItem[]>([]);
    const [formulaItemsUpdatedValues, setFormulaItemsUpdatedValues] = useState<any[]>([]);
    const [valuesAtZero, setValuesAtZero] = useState<boolean>(true);
    const [columnName, setColumnName] = useState<string>('');
    const [idOfferItem, setIdOfferItem] = useState<string>('');

    const defaultQuerySearch = [{ key: 'idOffer', value: idOffer }];

    const resetState = () => {
        setFormulaItemsUpdatedValues([])
        setUpdatedValues([]);
        setValuesAtZero(true);
    }

    return (
        <Mutation mutation={UPDATE_FORMULA_ITEM_MUTATION}>
            {(updateFormulaItem) => <Mutation mutation={UPDATE_OFFER_ITEM_MUTATION}>
                    {(updateOfferItem: (param: Record<'variables', updateOfferItemVariables>) => Promise<any>) => {
                        const onUpdate = (articles: updatedListTableServiceOfferItem[], multiLine: boolean, formulaItems: any[] = []) => {
                            const biggerLength = articles.length > formulaItems?.length ? articles.length : formulaItems?.length
                            let showSharedStockToast = false;
                            for (let i = 0; i < biggerLength; i++) {
                                if (!!articles[i]?.id) {
                                    const { id, quantityOverall, value, isStockShared } = articles[i];
                                    const finalQuantity = typeof value === 'number' ? quantityOverall + value : quantityOverall;
                                    !showSharedStockToast && isStockShared && (showSharedStockToast = true);
                                    updateOfferItem({
                                        variables: {
                                            id,
                                            quantityOverall: finalQuantity,
                                        },
                                    });
                                }
                                if (!!formulaItems[i]?.id) {
                                    const { id, quantityOverall, value, idOfferItem, isStockShared } = formulaItems[i];
                                    !showSharedStockToast && isStockShared && (showSharedStockToast = true);
                                    const updateMutation = !idOfferItem ? updateFormulaItem : updateOfferItem;
                                    updateMutation({
                                        variables: {
                                            id: !idOfferItem ? id : idOfferItem,
                                            quantityOverall: quantityOverall + value
                                        }
                                    })
                                }
                            }
                            if (multiLine) {
                                resetState();
                            }
                            showSharedStockToast && toast({ toastMessage:'UPDATE_COMMON_STOCK', type:'info' })
                        };

                        // check if the current item already exists on the updatedValues list
                        const handleUpdatedValues = (current, values, setValues) => {
                            const tempState = values.filter(
                                (el: updatedListTableServiceOfferItem) => el.id !== current.id
                            );
                            setValuesAtZero(false);
                            if (current.value === 0) {
                                setValues([...tempState]);
                            } else {
                                setValues([...tempState, current]);
                            }
                        }

                const onToSellUpdate = async(id: string, toSell: OfferItemToSell) => {
                    await updateOfferItem({
                        variables: {
                            id,
                            toSell,
                        },
                    });

                    checkItemsList(idOffer);
                };

                const renderLine = (item: listOfferItems_list_edges_node_OfferItem, key: number) => {
                    if (item.localArticle.container !== null) {
                        setColumnName(t('page:clickcollect.daily-offers.table.header.type') || '');
                    } else if (key === 0) {
                        setColumnName('');
                    }

                    return (
                        <tr key={`${item.id}-${item.inheritedLabel}-${key}`}>
                            <OfferItem
                                item={item}
                                valuesAtZero={valuesAtZero}
                                onClick={() => {
                                    setIdOfferItem(item.id);
                                }}
                                onChange={(data: updatedListTableServiceOfferItem) => {
                                    handleUpdatedValues(
                                        {
                                            ...data,
                                            idLocalArticle: item.localArticle.id
                                        },
                                        updatedValues,
                                        setUpdatedValues
                                    )
                                }}
                                onSubmit={(item) => {
                                    const newState = updatedValues.filter(
                                        (el: updatedListTableServiceOfferItem) => el.id !== item.id
                                    );
                                    onUpdate([item], false);
                                    setUpdatedValues(newState);
                                }}
                                hasContainerColumn={columnName.length > 0}
                                // @ts-ignore
                                onToSellUpdate={onToSellUpdate}
                                disableStockInput={formulaItemsUpdatedValues.some((formulaItem) => formulaItem?.idLocalArticle === item.localArticle.id)}
                                preCheckArticle={preCheckArticle}
                                onErrorArticle={onErrorArticle}
                            />
                        </tr>
                    );
                };

                const defaultQuerySearch = [{ key: 'idOffer', value: idOffer }];

                const getHeaders = (t: any) => [
                    {
                        key: 'inheritedLabel',
                        displayName: t('schema:offerItem.inheritedLabel'),
                    },
                    {
                        key: 'type',
                        displayName: columnName,
                        hide: columnName.length === 0,
                    },
                    {
                        key: 'add-stock',
                        displayName: t('page:clickcollect.daily-offers.table.header.stock'),
                    },
                    {
                        key: 'quantityRemaining',
                        displayName: t('schema:offerItem.quantityRemaining'),
                    },

                    {
                        key: 'toSell',
                        displayName: t('schema:offerItem.toSell'),
                    },
                    {
                        key: 'validate',
                        withoutPadding: true,
                        displayName:
                            updatedValues.length > 1 || formulaItemsUpdatedValues.length > 1
                            || (updatedValues.length === 1 && formulaItemsUpdatedValues.length === 1) ? (
                                <ValidateAll>
                                    <ValidateAllButtonWrapper>
                                        <Button
                                            onClick={() => onUpdate(updatedValues, true, formulaItemsUpdatedValues)}
                                            display={ButtonType.VALIDATION}
                                            shortVersion={columnName.length > 0}
                                        >
                                            {t('schema:offer.validateAll')}
                                        </Button>
                                    </ValidateAllButtonWrapper>
                                </ValidateAll>
                            ) : (
                                ''
                            ),
                    },
                ].filter(item => item !== null);

                        const renderFormulasTable = (isLoading, data, hasContainerColumn) => {
                            const activeOfferItems = data.list.edges.map(({node}) => ({ idOfferItem: node.id, idLocalArticle: node.localArticle.id, quantityOverall: node.quantityOverall }));
                            const formulaItems = data?.formulaItems;

                            if (formulaItems?.edges?.length === 0) {
                                return null;
                            }
                            const formulaItemsList = getNodes(formulaItems).reduce((acc: any[], eachFormula:any ) => {
                                if (typeof eachFormula.stepNumber !== 'number') {
                                    eachFormula.container?.label && setColumnName(t('page:clickcollect.daily-offers.table.header.type') || '');
                                    return [...acc, { ...eachFormula, family: formulasKey }];
                                }
                                return acc;
                            }, []);


                            const handleFormulasArticlesList = () =>{
                                const formulasArticlesList = getNodes(formulaItems).reduce((acc: any[], eachFormula: any) => {
                                    if (typeof eachFormula?.stepNumber === 'number') {
                                        const matchIndex = activeOfferItems.findIndex((item) => item.idLocalArticle === eachFormula.localArticle.id);
                                        if(matchIndex >= 0) {
                                            return [...acc, {
                                                ...eachFormula,
                                                idOfferItem: activeOfferItems[matchIndex].idOfferItem,
                                                quantityOverall: activeOfferItems[matchIndex].quantityOverall,
                                                isStockShared: true
                                            }];
                                        }
                                        return [...acc, eachFormula];
                                    }
                                    return acc;
                                }, []) as any[];

                                // formulaItems can be in several steps on another formulas and their stock will be shared
                                const duplicatedFormulaItems = !!formulasArticlesList.length && formulasArticlesList
                                    ?.map((item) => item?.localArticle?.id)
                                    .filter((id: string, index: number, array: string[]) => id && array.indexOf(id) !== index)
                                    .reduce((acc: string[], curr: string) => {
                                        if (!acc.includes(curr)) {
                                            acc.push(curr);
                                        }
                                        return acc;
                                    }, [])

                                return formulasArticlesList.map(item => (
                                    {
                                        ...item,
                                        isStockShared:
                                            item.isStockShared ? item.isStockShared : duplicatedFormulaItems.includes(item.localArticle.id)
                                    })
                                ) || []
                            }

                            return (
                                <FormulasTable
                                    isLoading={isLoading}
                                    formulasList={formulaItemsList}
                                    formulasArticlesList={handleFormulasArticlesList()}
                                    toggleCatalogModal={toggleCatalogModal}
                                    onUpdate={(data, deleteUpdatedValue: boolean) => {
                                        if (deleteUpdatedValue) {
                                            const newValues = formulaItemsUpdatedValues.filter((item) => item?.id !== data)
                                            return setFormulaItemsUpdatedValues(newValues)
                                        }
                                        handleUpdatedValues(data, formulaItemsUpdatedValues, setFormulaItemsUpdatedValues)
                                    }}
                                    hasContainerColumn={hasContainerColumn}
                                    valuesAtZero={valuesAtZero}
                                    disabledFormulaItemsStock={[...updatedValues, ...formulaItemsUpdatedValues]}
                                    updateOfferItemMutation={updateOfferItem}
                                />
                            );
                        };

                        return (
                          <>
                            <CheckOfferValidModal
                                toggleModal={() =>
                                  toggleValidationArticle(!showValidationArticle)
                                }
                                open={showValidationArticle}
                                articleErrorLabels={articleErrorState.items}
                                onClickItem={(id) => {
                                  setIdOfferItem(id);
                                  toggleValidationArticle(!showValidationArticle);
                                }}
                            />
                            <TableWithOptions
                                withRouting={false}
                                renderLine={renderLine}
                                renderSubHeader={(isLoading, data) => renderFormulasTable(isLoading, data, !!columnName.length)}
                                headers={getHeaders(t).filter((e) => !e.hide)}
                                pollInterval={30000}
                                query={LIST_OFFER_ITEMS_QUERY}
                                searchPlaceholder={t('app:placeholder.search.article')}
                                variables={{ querySearch: defaultQuerySearch }}
                                tags={{
                                    [QueryParamKeys.FAMILY]: getArticleFamilyOptions(),
                                }}
                                groupBy={{
                                    key: 'inheritedFamily',
                                    options: getArticleFamilyOptions(true, t(`schema:article.familyOption.OTHER`)|| ''),
                                }}
                                fetchPolicy="network-only"
                            >
                                {({ data, refetch }: QueryResult<listOfferItems>) => {
                                    const activeFormulasList = get(data, 'formulaItems.edges', []).reduce(
                                      // @ts-ignore
                                        (acc, eachFormulaItem: any) => {
                                            const formulaTemplate: any = get(eachFormulaItem, 'node.formulaTemplate', null);
                                            if (formulaTemplate) {
                                                return [
                                                    ...acc,
                                                    {
                                                        formulaId: formulaTemplate.id,
                                                        formulaOfferId: eachFormulaItem?.node?.id,
                                                    },
                                                ];
                                            }

                                            return acc;
                                        },
                                        []
                                    );
                                     // this value is set as true after we duplicate the offer items
                                    // we must refetch the new items after doing it
                                    if (shouldRefetchItems) {
                                        setShouldRefetchItems(false);
                                        resetState();
                                        refetch();
                                    }
                                    const closeModal = () => {
                                        refetch();
                                        resetState();
                                        toggleCatalogModal();
                                    };
                                    const closeEditOffer = () => {
                                        setIdOfferItem('');
                                        refetch();
                                    };
                                    return (
                                        <>
                                            <LocalCatalogTablePanel
                                                idOffer={idOffer}
                                                // @ts-ignore
                                                idPos={idPos}
                                                closeModal={closeModal}
                                                isOpen={isCatalogPanelOpen}
                                                activeFormulasList={activeFormulasList}
                                                formula={formula}
                                            />
                                             {idOfferItem &&
                                                <EditOfferItemPanel
                                                    // @ts-ignore
                                                    idOffer={idOffer}
                                                    idPos={idPos}
                                                    idHolding={idHolding}
                                                    idOfferItem={idOfferItem}
                                                    beforeClose={closeEditOffer}
                                                />
                                            }
                                        </>
                                    );
                                }}
                            </TableWithOptions>
                            </>
                        );
                    }}
                </Mutation>
            }
        </Mutation>
    );
};

const ButtonWrapper = styled.div`
    width: 100px;
    margin: auto;
    align-items: center;
    justify-content: center;
    display: flex;
`;

const QuantityWrapper = styled.div`
    text-align: center;
    width: 50%;
`;

const ValidateAll = styled.div`
    position: relative;
`;

const ValidateAllButtonWrapper = styled.div`
    width: 100%;
    position: relative;
`;

const TdWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;



export default withRouter(OfferItemTable);
