import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { RouteProps } from 'react-router-dom';
import Auth from 'services/auth0';
import User from 'components/Query/User';
import { GlobalLoader } from 'components/Loader';
import { PageContainer, HoldingPageContainer } from 'components/Layout';
import Demo from 'pages/Demo';
import ClickCollect from 'pages/ClickCollect';
import TableService from 'pages/TableService';
import Holding from 'pages/Holding';
import Admin from 'pages/Admin';
import CallBack from './Auth/CallBack';
import Login from './Auth/Login';
import appRoutes from './routes';
import HoldingIdCheck from './HoldingIdCheck';
import BookingService from 'pages/Reservations';
import Communication from 'pages/Communication';
import { isUserHasRole } from 'services/user';
import { whoami_tokenOwner_Employee } from 'types/whoami';
import Maintenance from 'pages/Maintenance';
import PasswordExpired from 'pages/Error/PasswordExpired';
import GlobalCatalog from 'pages/GlobalCatalog';
import CheckList from 'pages/CheckList';
import { EmployeeRole, Role } from 'types/globalTypes';
import auth0Client from 'services/auth0';
import { useToggleWebsocket } from 'components/Websocket/useWebSocket';

export const AdminRoute = ({ ...props }: RouteProps) => (
    <User>
        {({ user }) => {
            return user && user.role === EmployeeRole.Admin ? <Route {...props} /> : null;
        }}
    </User>
);

export const RouteWithRoles = ({ roles, ...props }: RouteProps & { roles: Role[] }) => (
    <User>
        {({ user }) => {
            return isUserHasRole(user, roles) ? <Route {...props} /> : null;
        }}
    </User>
);

const RouterComponent: React.FC = React.memo(() => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const {
        maintenance: { inMaintenance, isTester },
    } = useToggleWebsocket();

    const silentAuth = async () => {
        try {
            await Auth.silentAuth();
        } catch (error) {
            if (
                error &&
                (error as { description?: string }).description &&
                (error as { description?: string }).description!.startsWith('PASSWORD_EXPIRED')
            ) {
                auth0Client.signOutToPasswordExpired({
                    email: (error as { description?: string }).description!.split('|')[1],
                });
            }
            // @ts-ignore
            if (error.error !== 'login_required') console.log(error.error);
        } finally {
            setLoading(false);
        }
    }

    React.useEffect(() => {
        silentAuth();
    }, []);

    return (
        <Router>
            {inMaintenance && !isTester ? (
                <Switch>
                    <Route path="/" component={Maintenance} />
                </Switch>
            ) : !loading ? (
                <Switch>
                    <Route path="/password-expired" component={PasswordExpired} />
                    <Route path="/login" component={Login} />
                    <Route path="/callback" component={CallBack} />
                    <Route path="/demo" component={Demo} exact />
                    <Route
                        path="/:idHolding?"
                        render={(props) => {
                            return Auth.isAuthenticated() ? (
                                <MainLayout />
                            ) : (
                                <Redirect
                                    to={{
                                        pathname: '/login',
                                        state: { from: props.location },
                                    }}
                                />
                            );
                        }}
                    />
                </Switch>
            ) : (
                <GlobalLoader />
            )}
        </Router>
    );
});

const getDefaultPage = (user: whoami_tokenOwner_Employee | undefined, idHolding) => {
    if (!user) {
        return appRoutes.cnc.posSelect(idHolding);
    }

    if (user.role === EmployeeRole.Admin) {
        return appRoutes.admin.index();
    }

    if (user.role === EmployeeRole.Marketer) {
        return appRoutes.communication.list();
    }

    if (user.role === EmployeeRole.ChecklistAdmin) {
        return appRoutes.checkList.index();
    }

    return appRoutes.cnc.posSelect(idHolding);
};

const MainLayout = React.memo(() => (
    <User>
        {({ user }) => (
            <HoldingIdCheck userRole={user?.role}>
                {(idHolding, siteId) => (
                    <PageContainer>
                        <Switch>
                            <Route exact path="/" render={() => <Redirect to={getDefaultPage(user, idHolding)} />} />
                            <AdminRoute component={Admin} path={`${appRoutes.admin.index()}/:tab?`} />
                            <RouteWithRoles
                                component={GlobalCatalog}
                                roles={[Role.Admin, Role.ManagerAdmin]}
                                path={`${appRoutes.globalCatalog.index()}`}
                            />
                            <RouteWithRoles
                                component={Communication}
                                roles={[Role.Admin, Role.Marketer]}
                                path={`${appRoutes.communication.list()}`}
                            />
                            <RouteWithRoles
                                component={CheckList}
                                roles={[Role.Admin, Role.ChecklistAdmin]}
                                path={`${appRoutes.checkList.index()}/:tab?`}
                            />
                            <RouteWithRoles path="/:idHolding" roles={[Role.Admin, Role.Employee, Role.ChecklistAdmin]}>
                                <HoldingPageContainer>
                                    <Switch>
                                        <AdminRoute component={Holding} path={`${appRoutes.holding.index()}/:tab?`} />
                                        <Route component={ClickCollect} path={`${appRoutes.cnc.posSelect()}/:idPos?`} />
                                        <Route
                                            component={TableService}
                                            path={`${appRoutes.tableservice.posSelect()}`}
                                        />
                                        <Route
                                            component={BookingService}
                                            path={`${appRoutes.bookingservice.posSelect()}`}
                                        />
                                    </Switch>
                                </HoldingPageContainer>
                            </RouteWithRoles>
                        </Switch>
                    </PageContainer>
                )}
            </HoldingIdCheck>
        )}
    </User>
));

export default RouterComponent;
