import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import isEqual from 'lodash/isEqual';
import { Field, Form, Formik } from 'formik';
import { Panel, TextInputField, SubmitButton, InfoLabel } from 'components';
import * as Yup from 'yup';
import { PanelContent, PanelFooter } from 'components/Panel';
import LanguageTextInput from 'components/LanguageTextInput';
import { appTheme } from 'theme';
import { ToggleSwitchField } from "../Inputs";
import { NewTimeRangeInputField } from "../Inputs/FormikFields";
import TimeSlotInputField, { Time } from "../Inputs/FormikFields/TimeSlotInputField";
import { convertToDurationObject } from "../../utils";
import { convertObjectToISO } from "../../services/dateService";

const CREATE_PICK_UP_POINT_MUTATION = loader('../../query/createPickUpPoint.gql');
const UPDATE_PICK_UP_POINT_MUTATION = loader('../../query/updatePickUpPoint.gql');
const FORM_ID = 'pick_up_point_add_edit_form';

const timeUnitMinMax = {
    minutes: {
        min: 1,
        max: 59,
    },
    hours: {
        min: 1,
        max: 23,
    },
    days: {
        min: 1,
        max: 30,
    },
    minutesWithdraw: {
        min: 1,
        max: 720,
    },
};

const getSchema = (t: Function) =>
    Yup.object().shape({
        name: Yup.string().required(t('app:error.required')),
        deliveryTimes: Yup.string().notRequired().nullable(true),
        description: Yup.string().notRequired().nullable(true),
        address: Yup.string().notRequired().nullable(true),
        productionSite: Yup.string().notRequired().nullable(true),
        companyCode: Yup.string().notRequired().nullable(true),
        isSlotEnabled: Yup.boolean(),
        slotDuration: Yup.object().shape({
            duration: Yup.number()
              .integer(t('page:admin.cnc.form.error.invalidUnit') || undefined)
              .when('unit', (unit, schema) => {
                  if (unit === Time.MINUTES)
                      return schema
                        .min(
                          timeUnitMinMax.minutesWithdraw.min,
                          t('page:admin.cnc.form.error.heartMealRange', timeUnitMinMax.minutesWithdraw)
                        )
                        .max(
                          timeUnitMinMax.minutesWithdraw.max,
                          t('page:admin.cnc.form.error.heartMealRange', timeUnitMinMax.minutesWithdraw)
                        );
                  if (unit === Time.HOURS)
                      return schema
                        .min(
                          timeUnitMinMax.hours.min,
                          t('page:admin.cnc.form.error.heartMealRange', timeUnitMinMax.hours)
                        )
                        .max(
                          timeUnitMinMax.hours.max,
                          t('page:admin.cnc.form.error.heartMealRange', timeUnitMinMax.hours)
                        );
                  return schema;
              })
              .typeError(t('page:admin.cnc.required') || ''),
            unit: Yup.string(),
        }),

    }).test( // adds test function to the schema validation chain
      "deliveryTimesOrSlotsRequired",
      t('schema:pickUpPoints.deliveryTimeError'),
      (values) => {
          return !!values.deliveryTimes || !!values.isSlotEnabled;
      }
    );
interface IProps {
    match: {
        params: { idHolding: string };
    };
    pickUpPoint: any | null;
    isOpen: boolean;
    closeModal: ({ shouldRefetch }: { shouldRefetch?: boolean | undefined }) => void;
    isEditMode: boolean;
}

function PickUpPointTablePanel({
    isOpen,
    closeModal,
    match: {
        params: { idHolding },
    },
    pickUpPoint,
    isEditMode,
}: IProps & RouteComponentProps) {
    const { t } = useTranslation();
    const [mutatePickUpPoint] = useMutation<'variables', any>(
        isEditMode ? UPDATE_PICK_UP_POINT_MUTATION : CREATE_PICK_UP_POINT_MUTATION
    );

    const panelTitle = isEditMode
        ? t('page:clickcollect.pickUpPoints.pickUpPointsPanel.editTitle')
        : t('page:clickcollect.pickUpPoints.pickUpPointsPanel.title');

    // will get the Type after it is generated with gentypes
    const initialValues = {
        idHolding: idHolding,
        name: pickUpPoint ? pickUpPoint.name : '',
        description: pickUpPoint ? pickUpPoint.description : '',
        address: pickUpPoint ? pickUpPoint.address : '',
        productionSite: pickUpPoint ? pickUpPoint.productionSite : '',
        deliveryTimes: pickUpPoint ? pickUpPoint.withdrawalSchedule : '',
        // there isn't any field for the companyCode so far and it is required to create/update the pickup point
        companyCode: pickUpPoint ? pickUpPoint.companyCode : idHolding,
        isSlotEnabled: pickUpPoint ? pickUpPoint.isSlotEnabled : false,
        slotStartTime: pickUpPoint?.slotStartTime ?? 'PT12H0M',
        slotEndTime: pickUpPoint?.slotEndTime ?? 'PT15H0M',
        slotDuration: pickUpPoint?.slotDuration
          ? convertToDurationObject(pickUpPoint.slotDuration)
          : { duration: 5, unit: Time.MINUTES }
    };


    return (
        <Panel onClose={() => closeModal({})} open={isOpen} title={panelTitle}>
            <Formik
                initialValues={initialValues}
                validationSchema={getSchema(t)}
                validateOnBlur={false}
                validateOnChange={true}
                onSubmit={(values, { setSubmitting }) => {
                    mutatePickUpPoint({
                        variables: {
                            idHolding: values.idHolding,
                            name: values.name?.trim(),
                            description: values.description?.trim(),
                            address: values.address?.trim(),
                            productionSite: values.productionSite?.trim(),
                          ...(!values.isSlotEnabled ? { withdrawalSchedule: values.deliveryTimes?.trim() } : isEditMode ? { } : { withdrawalSchedule: '' }),
                            companyCode: values.companyCode?.trim(),
                            ...(isEditMode && { id: pickUpPoint.id }),
                            isSlotEnabled: values.isSlotEnabled,
                            ...(values.isSlotEnabled && {
                              isSlotEnabled: values.isSlotEnabled,
                              slotStartTime: values.slotStartTime,
                              slotEndTime: values.slotEndTime,
                              slotDuration: values.slotDuration
                                ? convertObjectToISO(values.slotDuration as { duration: number; unit: string })
                                : 'PT5M'
                            })
                        },
                    })
                        .then(() => {
                            closeModal({ shouldRefetch: true });
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
            >
                {({ errors, touched, values, initialValues }) => {
                    const { ...restInitialValues } = initialValues;
                    const { ...restValues } = values;
                    const formHasNoChange = isEqual(restInitialValues, restValues);

                    // .test function when returning an error, sets the error in the "undefined" key
                    const errorRelatedToDeliveryTimeOrSlots = errors['undefined'];
                    return (
                        <>
                            <PanelContent>
                              {
                                // @ts-ignore
                                <Form id={FORM_ID}>
                                    <Field
                                        label={t('schema:pickUpPoints.panelName')}
                                        placeholder={t('schema:pickUpPoints.panelNamePlaceholder')}
                                        name="name"
                                        component={TextInputField}
                                        style={{
                                            fontFamily: appTheme.typography.fontFamily,
                                        }}
                                        isPlaceholderBold
                                    />
                                    <Field
                                        label={t('schema:pickUpPoints.panelDeliveryTimes')}
                                        placeholder={t('schema:pickUpPoints.panelDeliveryTimesPlaceholder')}
                                        name="deliveryTimes"
                                        component={TextInputField}
                                        style={{
                                            fontFamily: appTheme.typography.fontFamily,
                                        }}
                                        isPlaceholderBold
                                        disabled={values.isSlotEnabled}
                                        warningMessage={values.isSlotEnabled ? t('schema:pickUpPoints.deliveryFieldNotUsed') : null}
                                    />
                                    <InfoLabel
                                      fontSize="M"
                                      name={t(`schema:pickUpPoints.slotsAreaTitle`)}
                                      withTooltip
                                      tooltipContent={t('schema:pickUpPoints.tooltip')}
                                      tooltipContainerStyle={{
                                        textSize: 13,
                                        lineHeight: 16
                                      }}
                                    />
                                    <Field
                                      name="isSlotEnabled"
                                      component={ToggleSwitchField}
                                      sideLabel={true}
                                      onLabel={t('schema:pickUpPoints.enabled')}
                                      offLabel={t('schema:pickUpPoints.enabled')}
                                    />
                                    { values.isSlotEnabled && <>
                                      <InfoLabel
                                        fontWeight="bold"
                                        fontSize="S"
                                        paddingBottom={2}
                                        name={t(`schema:pickUpPoints.slotDeliveryRange`)}
                                      />
                                      <Field
                                        id="guestCanGetOrder-input"
                                        label={t('page:admin.cnc.guestCanGetOrder')}
                                        width={89}
                                        labelStart={t(`page:admin.cnc.from`).toLowerCase()}
                                        labelEnd={t(`page:admin.cnc.to`)}
                                        labelEndInline={' '}
                                        name="withdrawalRange"
                                        fontWeight="normal"
                                        validateFormOnChange
                                        useDefaultErrorMessage={false}
                                        component={NewTimeRangeInputField}
                                        labelsFontSize="S"
                                        fontSize="S"
                                        marginBottom="xs"
                                        nameStart="slotStartTime"
                                        nameEnd="slotEndTime"
                                        disabled={!values.isSlotEnabled}
                                      />
                                      <InfoLabel
                                        fontWeight="bold"
                                        fontSize="S"
                                        name={t('schema:pickUpPoints.slotDeliveryDuration')}
                                        paddingBottom={3}
                                      />
                                      <Field
                                        id="timeToCollectOrder-input"
                                        label={t('page:admin.cnc.timeToCollectOrder')}
                                        fontSize="S"
                                        labelStart={t('page:admin.cnc.every')}
                                        labelsFontSize="S"
                                        name="slotDuration"
                                        validateFormOnChange
                                        useDefaultErrorMessage={false}
                                        component={TimeSlotInputField}
                                        selectData={[Time.MINUTES, Time.HOURS]}
                                        marginBottom="xxs"
                                        disabled={!values.isSlotEnabled}
                                      />
                                    </>}
                                    <LanguageTextInput
                                        label={t('schema:pickUpPoints.descriptionPanel')}
                                        placeholder={t('schema:pickUpPoints.panelDescriptionPlaceholder') || ''}
                                        name="description"
                                        maxLength={60}
                                        value={values.description}
                                        multiline
                                        style={{
                                            fontSize: `${appTheme.typography.fontSizeS}px`,
                                        }}
                                        isPlaceholderBold
                                    />
                                    <LanguageTextInput
                                        label={t('schema:pickUpPoints.addressPanel')}
                                        placeholder={t('schema:pickUpPoints.panelAddressPlaceholder') || ''}
                                        name="address"
                                        maxLength={300}
                                        value={values.address}
                                        multiline
                                        style={{
                                            fontSize: `${appTheme.typography.fontSizeS}px`,
                                        }}
                                        isPlaceholderBold
                                    />
                                    <LanguageTextInput
                                        label={t('schema:pickUpPoints.productionSitePanel')}
                                        placeholder={t('schema:pickUpPoints.productionSitePlaceholder')}
                                        name="productionSite"
                                        maxLength={300}
                                        value={values.productionSite}
                                        multiline
                                        style={{
                                            fontSize: `${appTheme.typography.fontSizeS}px`,
                                        }}
                                        isPlaceholderBold
                                    />
                                </Form>
                              }
                            </PanelContent>
                            <PanelFooter>
                              <SubmitButton
                                formHasNoChange={formHasNoChange}
                                form={FORM_ID}
                                disabled={
                                  formHasNoChange ||
                                  Object.entries(touched).length === 0 ||
                                  Object.entries(errors).length !== 0
                                }
                                errorToDisplay={errorRelatedToDeliveryTimeOrSlots}
                              />
                            </PanelFooter>
                        </>
                    );
                }}
            </Formik>
        </Panel>
    );
}

export default withRouter(PickUpPointTablePanel);
