import { Duration, DurationObject } from "luxon";

export const YEAR_INFINITY = 3000;

type delayedFunction = () => Promise<any>;

// only trigger func when milliseconds have passed since last call
export const debounce = (func: Function, milliseconds = 500) => {
    let timerId: number;

    return (...params: any[]) => {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            func(...params);
        }, milliseconds);
    };
}

// only trigger func when at least minTime have passed
export const delay = async (func: delayedFunction = () => Promise.resolve(), minTime = 500) => {
    const now = new Date();
    const res = await func();
    const elapsed = new Date().getTime() - now.getTime();
    return new Promise(resolve => setTimeout(() => resolve(res), minTime - elapsed));
};


// ImageSlider
export const ImageSlider = (sources: string[]) => {

    const firstHTMLPart = `
    <html>
    <head>
    <style>
    * {box-sizing: border-box}
    body {font-family: "Open Sans";margin: 0;display: flex;}
    .mySlides {display: none}
    img {vertical-align: middle;}
    
    /* Slideshow container */
    .slideshow-container {
      max-width: 1000px;
      position: relative;
      margin: auto;
    }

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
    
    /* Next & previous buttons */
    .prev, .next {
      cursor: pointer;
      position: absolute;
      top: 50%;
      width: 35px;
      height: 35px;
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 7px;
      margin-top: -22px;
      margin-left: 5px;
      color: rgba(56,56,56,0.7);
      font-weight: bold;
      font-size: 18px;
      transition: 0.6s ease;
      border-radius: 100px;
      user-select: none;
      background-color: rgba(217,218,221,0.7);
    }
    
    /* Position the "next button" to the right */
    .next {
      right: 0;
      margin-right: 5px;
      padding-left: 14px;
    }
    
    /* On hover, add a black background color with a little bit see-through */
    .prev:hover, .next:hover {
      color: rgba(56,56,56,1);
      background-color: rgba(217,218,221,1);
    }
    
    /* Fading animation */
    .fade {
      -webkit-animation-name: fade;
      -webkit-animation-duration: 1.5s;
      animation-name: fade;
      animation-duration: 1.5s;
    }
    
    @-webkit-keyframes fade {
      from {opacity: .4} 
      to {opacity: 1}
    }
    
    @keyframes fade {
      from {opacity: .4} 
      to {opacity: 1}
    }
    
    </style>
    </head>
    <body>
    
    <div class="slideshow-container">
    `

    const finalHTMLPart = `
    <a id="prevButton" class="prev" onclick="plusSlides(-1)">&#10094;</a>
    <a id="nextButton" class="next" onclick="plusSlides(1)">&#10095;</a>
    
    </div>
    <br>
    
    <script>
    var slideIndex = 1;
    showSlides(slideIndex);
    
    function plusSlides(n) {
      showSlides(slideIndex += n);
    }

    function showSlides(n) {      
      const slides = document.getElementsByClassName("mySlides");
      const prevButton = document.getElementById("prevButton");
      const nextButton = document.getElementById("nextButton");

      if (n > slides.length) { slideIndex = 1 }    
      if (n < 1) { slideIndex = slides.length }

      for (let i = 0; i < slides.length; i++) {
          slides[i].style.display = "none";
      }
      slides[slideIndex-1].style.display = "block";

      if (slides.length === 1) {
        prevButton.style.display = "none";
        nextButton.style.display = "none";
      }
    }
    </script>
    </body>
    </html>
    `

    const createSlide = (source: string) => `
    <div class="mySlides fade">
        <img src="${source}" class="image">
    </div>
  `
  const contentHTML = sources.map((source) => (createSlide(source)))

  return firstHTMLPart + contentHTML.join('\n') + finalHTMLPart;
}

//Convert a local image to a Blob (from base64 to File)
//Format of a base64-encoded URL: dataURI:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAYAAAAEOCAIAAAAPH1dAAAAK
export const  dataURItoFile = (dataURI:string) => {
  const BASE64_MARKER = ';base64,';
  const type = dataURI.split(BASE64_MARKER)[0].split(':')[1];
  const filename = 'infoNewsBackground-' + (new Date()).getTime() + '.' + type.split('/')[1];
  const bytes = atob(dataURI.split(BASE64_MARKER)[1]);
  const writer = new Uint8Array(new ArrayBuffer(bytes.length));

  for (let i=0; i < bytes.length; i++) {
    writer[i] = bytes.charCodeAt(i);
  }
  return new File([writer.buffer], filename, { type });
};

// Check if URL is Valid
// If used inside formik, please use Yup
export const isValidURL = (url: string) => {
  try {
    new URL(url);
  } catch (err) {
    console.error(err)
    return false;
  }
  return true;
};

const base64ToEntityId = (b: string) => {
    return atob(b);
}

/**
 * Decodes the id of any entity.
 * An entity id follows the format -> entity:numericId
 *
 * It is expected the id to be encoded in base64.
 * However, on local env the id will not be encoded in base 64, so if it fails to decode the id
 * as base 64, it will assume it's already decode and will get and return the numericId.
 *
 * @param id
 */
export const decodeEntityId = (id) => {
    let entityId = '';

    try {
        entityId = base64ToEntityId(id);
    } catch (error) {
        // will throw error when trying to decode base64 id with character ':', which occurs in local env,
        // where id is entity:id instead of being encoded in base64
        entityId = id;
    }
    return entityId.split(':')[1];
}

/**
 * Extracts the filename from the value of the http response header 'content-disposition'
 * Follows a naive approach to extract the filename.
 * Returns the filename. If it can't extract it, returns null.
 *
 * Supported case:
 *  value: attachment; filename="ab.xlsx"
 *  will search for filename= and extract and return ab.xlsx
 */
export const extractFilenameFromHeader = (value: string | null) => {
    if (!value) {
        return null;
    }

    const filenameSection = value.split('filename=')[1];
    if (!filenameSection) {
        return null;
    }

    // start at 1, and discard last character, because filename is wrapped in double quotes. ex: "my.name.txt"
    return filenameSection.slice(1, filenameSection.length - 1);
}

  /**
    * Receives a blob (file like data) that will be used to create an anchor html tag associated to the file to
    * download and the respective filename.
    * After creating the anchor tag the file download will be triggered and the browser will display a
    * modal to save the file.
    *
    * @param blob
    * @param filename
    */
  export const triggerBrowserFileDownload = async (blob: Blob, filename: string): Promise<void> => {
    const objectURL = URL.createObjectURL(blob);

    const anchor = document.createElement('a');
    anchor.href = objectURL;
    anchor.download = filename;

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    URL.revokeObjectURL(objectURL);
  };

/**
 * Determine if the current environment is testing/development or if it's a production env.
 *
 * It's considered a production env if the value of process.env.NODE_ENV is different of 'development' and
 * if the API graphql resource is equal to the API graphql resource used in the production or equal to the
 * API graphql resource used in integration or beta environment.
 * The integration and beta env are considered as production, since they are environments that should mimic the production
 * env as close as possible.
 *
 * The API graphql resource is being checked because some changes might be wanted in the testing environment, and the
 * node_env field is no longer with the 'development' value.
 */
 export const isTestingEnvironment = () => {
  // will check if the api is the production one or the one used in beta or integration env
  const productionEnvironments = new RegExp('https:\\/\\/api\\.(|beta.|integration.)foodi\\.fr\\/graphql');
  return process.env.NODE_ENV === 'development' || !productionEnvironments.test(window.config.API_GRAPHQL_ENDPOINT);
}

export const FRANCE_TIMEZONE = 'Europe/Paris';

export const convertToDurationObject = (value) => {
  let durationISO: DurationObject = {};

  if (value.includes('M')) {
    // As long as the duration have 1 minute we will convert it all to minutes
    // Ex. 2 hours and 1 minute will appear as 121 minutes
    //
    // This is needed for the withdrawSlotDuration in Step2, we increased the max minutes to 720
    // and if we dont make this change then it would display the time incorrectly
    durationISO = {
      minutes: Duration.fromISO(value).as('minutes'),
    };
  } else {
    durationISO = Duration.fromISO(value).normalize().toObject();
  }
  const durationLabel: string = Object.keys(durationISO)[0].charAt(0) + Object.keys(durationISO)[0].slice(1);

  return {
    duration: durationISO ? durationISO.minutes || durationISO?.hours || durationISO?.days : 0,
    unit: durationLabel,
  };
};
