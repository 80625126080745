import isEqual from 'lodash/isEqual';
import { TextInputField, SubmitButton, SelectField } from 'components';
import { PanelContent, PanelFooter } from 'components/Panel';
import { Field, Form, Formik } from 'formik';
import { loader } from 'graphql.macro';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { useTranslation } from 'react-i18next';
import { holdingPos_zones_edges_node_Zone } from 'types/holdingPos';
import { updateZoneVariables } from 'types/updateZone';
import { ZoneStatus } from 'types/globalTypes';
import * as Yup from 'yup';

const FORM_ID = 'zone_edit_form';

const UPDATE_ZONE_MUTATION = loader('../query/updateZone.gql');

const getSchema = (t: Function) =>
    Yup.object().shape({
        name: Yup.string().required(t('app:error.required')),
        code: Yup.string().required(t('app:error.required')),
        status: Yup.string().required(t('app:error.required')),
    });

interface IEditZone {
    onSuccess?: Function;
    zone: holdingPos_zones_edges_node_Zone;
}

const EditZone = ({ zone, onSuccess }: IEditZone) => {
    const initialValues = {
        id: String(zone.numericId),
        code: zone.code || '',
        name: zone.label || '',
        status: zone.status || ZoneStatus.Visible
    };
    const [t] = useTranslation();
    return (
        <Mutation mutation={UPDATE_ZONE_MUTATION}>
            {(updateZone: (param: Record<'variables', updateZoneVariables>) => Promise<any>) => (
                <Formik
                    initialValues={initialValues}
                    validationSchema={getSchema(t)}
                    onSubmit={(values, { setSubmitting }) => {
                        updateZone({
                            variables: {
                                ...values,
                                id: zone.id,
                                description: zone.description,
                            },
                        })
                            .then(({ errors }: any) => {
                                if (errors) {
                                    throw errors;
                                }
                                if (onSuccess) {
                                    onSuccess();
                                }
                                setSubmitting(false);
                            })
                            .catch((error: any) => {
                                console.log(error);
                                setSubmitting(false);
                            });
                    }}
                >
                    {({ errors, touched, values, initialValues }) => (
                        <>
                            <PanelContent>
                                {
                                  // @ts-ignore
                                  <Form id={FORM_ID}>
                                    <Field
                                        label={t('schema:zone.id')}
                                        name="id"
                                        component={TextInputField}
                                        disabled
                                        onChange={() => {}}
                                    />

                                    <Field label={t('schema:zone.code')} name="code" component={TextInputField} />
                                    <Field label={t('schema:zone.name')} name="name" component={TextInputField} />
                                    <Field
                                      label={t('schema:zone.status')}
                                      name="status"
                                      data={Object.values(ZoneStatus).map((id) => ({
                                        id,
                                        label: t(`schema:ZoneStatusTypes.${id}`),
                                      }))}
                                      component={SelectField}
                                      withTooltip
                                      tooltipContent={t('schema:zone.tooltip')}
                                    />
                                  </Form>
                                }
                            </PanelContent>
                            <PanelFooter>
                                <SubmitButton
                                    formHasNoChange={isEqual(values, initialValues)}
                                    form={FORM_ID}
                                    disabled={
                                        isEqual(values, initialValues) ||
                                        Object.entries(touched).length === 0 ||
                                        Object.entries(errors).length !== 0
                                    }
                                />
                            </PanelFooter>
                        </>
                    )}
                </Formik>
            )}
        </Mutation>
    );
};
export default EditZone;
