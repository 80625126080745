import { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import React from 'react';
import _ from 'lodash';
import useWebSocket from 'react-use-websocket';
import messageHandler from './websocket-actions/messageHandler';
import { jwtDecode } from 'jwt-decode';

type Context = {
    maintenance: {
        inMaintenance: boolean;
        isTester: boolean;
    };
};

const ToggleWebsocketContext = createContext<Context>({
    maintenance: {
        inMaintenance: false,
        isTester: false,
    },
});

export const useToggleWebsocket = () => useContext(ToggleWebsocketContext);

type ToggleWebsocketProviderProps = {
    children: ReactNode;
};

type Message = {
    data: string;
};

export type MessageData = {
    type: string;
    data: [];
};

export const ToggleWebsocketProvider = ({ children }: ToggleWebsocketProviderProps) => {
    const [inMaintenance, setInMaintenance] = useState<boolean>(false);
    const [isTester, setIsTester] = useState(false);
    const [wsUrl, setWsUrl] = useState<string>('');

    const accessToken = window.localStorage.getItem('accessToken');

    useEffect(() => {
        if (accessToken) {
            const clientInfo = jwtDecode(accessToken) as Record<string, string>;
            setIsTester(Boolean(clientInfo['https://foodi.com/isTester']));
        }
    }, [accessToken]);

    const environment = window.config.FOODI_ENV || 'LOCAL';
    const applicationId = 'FOODI-BACKOFFICE';

    const setters = {
        setInMaintenance,
    };

    const onMessageHandler = (message: Message, lastJsonMessage: any) => {
        const data: MessageData = JSON.parse(message.data);
        if (!data || _.isEqual(data, lastJsonMessage) || !messageHandler[data.type]) {
            return;
        }

        return messageHandler[data.type](data, lastJsonMessage, setters, environment);
    };

    const fetchToken = async () => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                operationName: 'getToggleToken',
                variables: {
                    type: 'read',
                },
                query: 'query getToggleToken($type: String!) {\n  getWsToken(type: $type) {\n    access_token\n    message\n    __typename\n  }\n}\n',
            }),
        };

        const data = await fetch(window.config.API_GRAPHQL_ENDPOINT, requestOptions)
            .then((response) => response.text())
            .then((result) => JSON.parse(result));

        const wsAccessToken = data?.data?.getWsToken?.access_token;

        return wsAccessToken ?? '';
    };

    useEffect(() => {
        fetchToken().then((wsAccessToken) => {
            if (wsAccessToken) {
                setWsUrl(`wss://${window.config.WEBSOCKET_URL}/?token=${wsAccessToken}`);
            }
        });
    }, []);

    const { sendJsonMessage, lastJsonMessage } = useWebSocket(wsUrl, {
        shouldReconnect: () => true,
        onOpen: () => {
            console.info('[Websocket] Connection opened successfully');
            sendJsonMessage({
                action: 'retrieveFeatures',
                application_id: applicationId,
                environment,
            });
        },
        onMessage: (message) => {
            onMessageHandler(message, lastJsonMessage);
        },
        onClose: () => {
            console.info('[Websocket] Closed connection successfully');
        },
        share: true,
        heartbeat: {
            message: JSON.stringify({ action: 'ping' }),
            returnMessage: JSON.stringify({ type: 'PONG' }),
            interval: 1000 * 60,
            timeout: 1000 * 60 * 5,
        },
        onError: (event) => {
            console.error('[Websocket] Could not connect or maintain connection: ', event);
        },
        retryOnError: true,
        reconnectAttempts: 5,
        reconnectInterval: 1000 * 60,
        onReconnectStop: (numAttempts) => {
            console.info(`[Websocket] Reconnection stopped after ${numAttempts} attempts`);
        },
    });

    return (
        <ToggleWebsocketContext.Provider
            value={{
                maintenance: {
                    inMaintenance,
                    isTester,
                },
            }}
        >
            {children}
        </ToggleWebsocketContext.Provider>
    );
};
